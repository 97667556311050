import React,{useEffect,useState} from 'react'
import {Form,Input,Button,Typography, Divider } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import axios from 'axios';
import { Navigate } from 'react-router-dom';

const Reset = () => {
  
  const [redirect,setRedirect]=useState(false)
    const [email,setEmail]=useState('')
    const [msg,setMessage]=useState('')
    const [error,setError]=useState('')
    const handlePass=async()=>{
await axios.post('https://googlevend.com/password-reset/',{
  
    email:email
}).then(function(response){
    setMessage(response.data)
   
console.log(msg['detail'])
}).catch(error=>{
    setMessage(error.response.data)
    

})

    }
  return (
    <div>
      <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      
    >
      <Form.Item>
      <Typography  strong style={{ fontSize: '100%','text-align': 'left','padding-left': '1%',color:'green'}} type='danger' >Please provide Your Valid Email Address to Reset Password</Typography>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={e=>{setEmail(e.target.value)}} value={email}  />

      </Form.Item>
      <Button htmlType='submit' onClick={handlePass}>Send</Button>
      </Form>
      <Divider/>
      <Typography strong style={{ fontSize: '100%','text-align': 'left','padding-left': '1%',color:'purple'}}> {msg['detail']}</Typography>
      <Typography strong style={{ fontSize: '100%','text-align': 'left','padding-left': '1%',color:'purple'}}> {msg['email']}</Typography>
      
    </div>
  )
}

export default Reset
