import React,{useState,useEffect} from 'react'

import {
    
    Typography,
    
    Form,
    Input,
   Button,
   Divider
   
   
    
  } from 'antd';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
const Register = () => {
    const { Text, Link } = Typography;
    const [form] = Form.useForm();
    const[dataEmail,setEmailData]=useState('')
    const [first,setFirst]=useState('')
  const [last,setLast]=useState('')
  const [email,setEmail]=useState('')
  const [msg,setMsg]=useState('')
  const [error,setError]=useState('')
  
  const [password,setPassword]=useState('')
  const[password1,setPassword1]=useState('')
  const[redirect,setRedirect]=useState(false)
 const handleRegister=async()=>{
  
   await axios.post('https://googlevend.com/accounts/register/',{
    username:email,
     email:email,
     
     password1:password,
     password2:password1,
     first_name:first,
     last_name:last
    

   })
   .then(function(response){
 
    setMsg(`Please check your email ${email} and click on the link to confirm your Registration. Also, please ensure that the email address you provided is valid.  `)
   })
     
     
   
   

   


   .catch(function(error){
    setError(error.response.data)

   })
    
    

   
   
  }
 
  

    


    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };
  return (

      

     
       <div className="login-form-button">
          <Text  strong  style={{ fontSize: '100%','text-align': 'center','padding-left': '10%'}} type='warning'>{msg}  </Text>
       <Divider/>
     
      
       <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      
    
      
     // onFinish={onFinish}
     
    >
     <Form.Item>
     <Text  strong  style={{ fontSize: '200%','text-align': 'center','padding-left': '10%'}} type='warning'>Create your Account
       </Text>
     </Form.Item>
      
    
      
       
    
    
    
    
<Form.Item
  name="email"
  label="E-mail"
  rules={[
    {
      type: 'email',
      message: 'The input is not valid E-mail!',
    },
    {
      required: true,
      message: 'Please input your E-mail!',
    },
   
  ]}
>
  <Input onChange={(e)=>{setEmail(e.target.value)}} value={email} />

  
</Form.Item>
<Typography.Text strong type='warning' > {error['email']} </Typography.Text>
<Form.Item
  name="password"
  label="Password"
  rules={[
    {
      required: true,
      message: 'Please input your password!',
    },
  ]}
  hasFeedback
>
  <Input.Password onChange={(e)=>{setPassword(e.target.value)}} value={password} />
  
  
</Form.Item>
<Typography.Text strong type='warning'>{ error['password1']} </Typography.Text>
<Form.Item
  name="confirm"
  label="Confirm Password"
  dependencies={['password']}
  hasFeedback
  rules={[
    {
      required: true,
      message: 'Please confirm your password!',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
    }),
  ]}
>
  <Input.Password onChange={(e)=>setPassword1(e.target.value)} value={password1} />
  
 
</Form.Item>
<Typography.Text strong type='warning'>{ error['password2']}</Typography.Text>
<Form.Item
  name="Firstname"
  label="Firstname"
  tooltip="Please input your First Name!"
  rules={[{ required: true, message: 'Please input your First Name!', whitespace: true }]}
>
  <Input  onChange={(e)=>{setFirst(e.target.value)}} value={first}/>
  
</Form.Item>
<Typography.Text  strong type='warning'>{ error['first_name']} <br></br></Typography.Text>
<Form.Item
  name="Lastname"
  label="Lastname"
  tooltip="Please input your Last Name!"
  rules={[{ required: true, message: 'Please input your Last Name!', whitespace: true }]}
>
  <Input onChange={(e)=>{setLast(e.target.value)}} value={last} />
  
  </Form.Item>
  <Typography.Text  strong type='warning'>{ error['last_name']}<br></br></Typography.Text>
  <Button type="primary" htmlType="submit"  onClick={handleRegister} >Register</Button>
 
  
 </Form>
 
       
      
      </div>
      

  
  )
  

}

export default Register
