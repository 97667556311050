import React,{useState,useEffect} from 'react'
import axios from 'axios'
import '../index.css';
import Login from './Login'
import { Navigate } from 'react-router-dom'
import { Form, Input, Button ,Select,Space,Divider,Image,Typography,notification} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import pic from './_home.png'          
const Entry = () => {
  const{Option}=Select
  const [api, contextHolder] = notification.useNotification();
    const [message,setMessage]=useState('')
    const mytoken=localStorage.getItem('token')
    const[image,setImage]=useState('')
    const [title,setTitle]=useState('')
    const [prod,setProd]=useState('')
    const [contact,setContact]=useState('')
    const [addr,setAddr]=useState('')
    const [trans,setTrans]=useState('')
    const [goods,setGoods]=useState('')
    const [redirect,setRedirect]=useState(false)
    const[redirect2,setRedirect2]=useState(false)
    const[authData,setAuth]=useState([''])
    const[msg,setMsg]=useState('')
    const[ok,setOk]=useState('')
    
    const [author,setAuthor]=useState('')
    const [authName,setAuthName]=useState('')
    
    const handleImage=(event)=>{

      setImage(event.target.files[0])
    }
    const fData=new FormData()
    fData.append('title',title)
    fData.append('Prod_desc',prod)
    fData.append('contact_me',contact)
    fData.append('address',addr)
    fData.append('img',image)
    fData.append('transaction',trans)
    fData.append('Good_type',goods)
    fData.append('author',author)
    fData.append('Names',authName)
    
    useEffect(()=>{
      fetchData()
    
          },[])
         
          useEffect(()=>{
       
          },[])
//const authFilter=()=>{
  //setPrivate(authData && authData.filter((item)=>{
   // return(
    //  (item.author===author)
    //)

  //}))



//}

    const Submit=async()=>{
      await axios.post('https://googlevend.com/vend/',fData,{
        
      
      
        
        

       
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${mytoken}`
        },
        
       
        
      }).then(function(response){
        
        setAuth(response.data)
       
        
        api.open({
          message: 'Success',
          description:
            'Data has been posted successfully. ',
            duration: 4,
          icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        });
        window.location.reload(false);
       
      }).catch(function(error){
        setMsg(error.response.data)

      })

      

      

      
      


    }
    
    
    const handleTrans=(value)=>{
      setTrans(value)
    }
    const handleGoods=(value)=>{
      setGoods(value)
    }
   


    
        
    const fetchData=async()=>{
        await axios.get('https://googlevend.com/accounts/user/',{
            
                headers :{
                    Authorization: `Token ${mytoken}`,
                
            }
        }).then(function(response){
         
         
          setAuthor(response.data.pk)
          setAuthName(response.data.first_name +" " +response.data.last_name)
            setMessage(` ${response.data.first_name}  ${response.data.last_name}`)


        }).catch(function(error){
            setMessage(`Error Occour  Please Login first`)

        })
       

        }
      
        
      
    

  return (
    <div  >
      {mytoken?<div><div className="login-form">
      {contextHolder}
      <h1>Add Your Product Information</h1>
      
      <div>
      <Form.Item name="Title" label="Product Name"
        rules={[{ required: true, message: 'Please input your Product Name!', whitespace: true }]}
      >
            <Input value={title} onChange={(e)=>{setTitle(e.target.value)}} />
            <Typography.Text  mark strong level={1} type='danger'>{msg['title']}</Typography.Text>
</Form.Item>

<Form.Item name="Conta" label="Your Contact(Email/phone or else)"
rules={[{ required: true}]}>
            <Input value={contact} onChange={(e)=>{setContact(e.target.value)}} />
            <Typography.Text  mark strong level={1} type='danger'>{msg['contact_me']}</Typography.Text>
</Form.Item>
<Form.Item name="addr" label="Address"
rules={[{ required: true}]}>
            <Input value={addr} onChange={(e)=>{setAddr(e.target.value)}} />
            <Typography.Text  mark strong level={1} type='danger'>{msg['address']}</Typography.Text>
</Form.Item>
<Form.Item name="ProdDesc" label="Product description"
rules={[{ required: true}]}>
            <Input.TextArea  value={prod} onChange={(e)=>{setProd(e.target.value)}}  />
            <Typography.Text  mark strong level={1} type='danger'>{msg['Prod_desc']}</Typography.Text>
</Form.Item>
<Form.Item name="Trans" label="Transaction " rules={[{ required: true}]}>
            <Select onChange={handleTrans} value={trans}>

            <Option value="Buying-Goods">Buying</Option>
            <Option value="Selling-Goods">Selling</Option>
              </Select>
              <Typography.Text  mark strong level={1} type='danger'>{msg['transaction']}</Typography.Text>
</Form.Item>
<Form.Item name="goodType" label="Good Type " rules={[{ required: true}]}>
           <Select value={goods} onChange={handleGoods}>
           <Option value="Homemade">Homemade</Option>
            <Option value="Manufactured_Good ">Manufactured_Good</Option>
  <Option value="Services">Services</Option>
  <Option value="Others">Others</Option>
  <Option value="Secondhand_Goods">Secondhand_Goods</Option>
           </Select>
           <Typography.Text  mark strong level={1} type='danger'>{msg['Good_type']}</Typography.Text>
</Form.Item>
<Form.Item name="file" label="upload Your Product Image">
            <Input type='file' value={image} onChange={handleImage} />
</Form.Item>
<Typography.Text  mark strong level={1} type='danger'>{msg['img']}</Typography.Text>
<Button type="primary" htmlType="submit" onClick={Submit} className="login-form-button">
          Post
        </Button>
       
   
        
 
  
  </div>
  
  
      </div> 
      
          <div>
      


        </div>
        </div>
      :
      <div> Before Post Your Data You Should Login First</div>
      }
     
    </div>
  )
}

export default Entry
