import React, { useState,useEffect } from 'react'
import { Typography,Form,Input,Select, Button,notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons';
import axios from 'axios'
const FeedBack = () => {
  const [api, contextHolder] = notification.useNotification();
    const token=localStorage.getItem('token')
    const [title,setTitle]=useState('')
    const[msg,setMsg]=useState('')
    const[mType,setType]=useState('')
    const[pic,setPic]=useState('')
    const [pk,setPk]=useState('')
    const [error,setError]=useState('')
    const FetchU=async()=>{
await axios.get('http://googlevend.com/accounts/user/',{
  headers:{
    Authorization:`Token ${token}`
  }
}).then(function(response){
  setPk(response.data.pk)
  

})
    }
    useEffect(()=>{
      FetchU()

    },[])
    const handleFeed=async()=>{
await axios.post('https://googlevend.com/feed/',{
  title:title,
  msg:msg,
  msg_type:mType,
  img:pic,
author:pk

  },
  {

  headers:{
    'Content-Type': 'multipart/form-data',
    Authorization:`Token ${token}`
  }
}).then(function(response){
  api.open({
    message: 'Success',
    description:
      'Message Sent successfully.',
    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
  });

}).catch(function(error){
  setError(error.response.data)

})
    }
    const HanndleImg=(e)=>{
      setPic(e.target.files[0])
    }
    const Typehandle=(value)=>{
      setType(value)

    }
    const handleMsg=(e)=>{
      setMsg(e.target.value)
    }
    const {Option}=Select
  return (
    <div>{token?
        
        <div>
            <Typography.Title  strong style={{color:'#010118'}} level={1}>Report an issue or send feedback </Typography.Title>
            
<Typography.Title  strong style={{color:'#010118'}} level={3}> You can help improve  Google Vend by giving us feedback about any problems you're having</Typography.Title>


{contextHolder}
      <div  >
        <Form className="login-form">
      <Form.Item name="Subject" label=" Message Subject"
        rules={[{ required: true, message: 'Please input your Message Subject !', whitespace: true }]}
      >
            <Input value={title} onChange={(e)=>{setTitle(e.target.value)}} />
            {error['title']}
            </Form.Item>
            <Form.Item name="Message Type" label=" Message Type"
       rules={[{required:true,message:'Please Choose Message Type'}]}
      >

        <Select value={mType} onChange={Typehandle}>
        <Option value='FeedBack'>FeedBack</Option>
          <Option value="Repot">Repot</Option>
          
        </Select>
        
        </Form.Item>
        {error['msg_type']}
        <Form.Item name="Msg" label="Your Message"
        rules={[{required:true,message:'Please Input Your Message'}]}>
          <Input.TextArea value={msg} onChange={handleMsg}/>

          {error['msg']}
        </Form.Item>
        
        <Form.Item name='img' label={<p>Image of problem  or<br></br> feedback (optional)</p>}>
        <Input type='file' value={pic} onChange={HanndleImg}/>
{error['img']}
        </Form.Item>

        <Button type='primary' htmlType='submit' onClick={handleFeed}>Send</Button>
            </Form>
        </div>
      </div>
:  <div> <Typography.Title level={2} type='warning'>To post Feedback/Report please log in first.</Typography.Title> </div>}
    </div>
  )
}

export default FeedBack
