import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Typography,Input,Space,Select,Divider,Row,Col,Card,Image,Layout, Form,Descriptions  } from 'antd';
import homepic from './_home.png'

const Home = () => {
  const { Text, Link } = Typography;
  const { Option } = Select;
  const [trans,setTrans]=useState('')
  const [search,setSearch]=useState('')
  const [goods,setGood]=useState('')
  const[mydata,setMyData]=useState('')
  const[count,setCount]=useState('')
  const[myFiltdata,setFiltMyData]=useState('')
   const handleTran=(value)=>{
     setTrans(value)
     
   }
   const handleSearch=(value)=>{
     setSearch(value.target.value)
   }
   
   const handleGoods=(value)=>{
     setGood(value)
   }
   const fetchData= async()=>{
     await axios.get('https://googlevend.com/vend/')

     .then(function (response){
       
       
       setMyData(response.data)
     
       setCount(response.data.count)
     })
     .catch(function (error) {

   })
  }
  useEffect(()=>{
fetchData()
  },[])
  const filterData=()=>{
    setFiltMyData(mydata && mydata.filter((item)=>{
      return(
        item.title.toLowerCase().includes(search.toLowerCase()) &&
        (trans==='' || item.transaction===trans) &&
        (goods===''|| item.Good_type===goods)
      )
    }))
    
  }
useEffect(()=>{
  filterData()
})
  return (
    <div>
      <div  >
      <div> < Typography.Title strong={true} level={1}   style={{color:'#ee124e'}} > Welcome To Google Vend <br></br><br></br>  </Typography.Title >
    
      
      <Card title={<Typography.Title  bold level={4}  style={{color:'#4c0385'  }}> Digital Marketing with Google Vend <br></br>  </Typography.Title >} bordered={true}>
      <Typography.Title bold level={3}  style={{color:'#4c0385'} }>
  &#x2022; Create/Login Account on Google Vend.<br></br>
  &#x2022; Create Your Post.<br></br>
  &#x2022; Share on your favourite social media platform.
  </Typography.Title>
      </Card>
      
      </div>
      

  
      <Space split={<Divider type="vertical" />}>

      
      < Typography.Title bold level={2}  style={{color:'#4c0385'}} >The Best Online  platform for growing your Personal Business.<br></br>  Place To Buy and Sell  properties safely.   </Typography.Title >
      
  

  
      
      <Image preview={false}   src={homepic}/>
      
    </Space>
     <div>
  
     

     
     </div>
    

    
       
    <Row>
      <Col span={12}>
      
      </Col>
      
      </Row>

      <div className="login-form">
        <Form.Item>
        <Input  value={search} onChange={handleSearch} addonBefore ={<Typography.Text level={2}  style={{color:'#035285'}} >Search Product By Name</Typography.Text>}/>
        </Form.Item>
     <Form.Item>
       
        <Typography.Title level={3} strong style={{color:'#25fd60',backgroundColor: '#4e0903'}} >Filter Product By </Typography.Title>
       
        </Form.Item>
        <Form.Item>
          <Typography.Text level={1} strong style={{color:'#25fd60',backgroundColor: '#799F0C'}} >Transaction Type</Typography.Text>
        </Form.Item>
        <Form.Item>
        <Select   value={trans}style={{width:'100px'}} onChange={handleTran}>


<Option value=''>All Type</Option>
  <Option value="Buying-Goods">Buying</Option>
  <Option value="Selling-Goods">Selling</Option>
</Select>
</Form.Item>
      <Form.Item>
      <Typography.Text level={1} strong style={{color:'#25fd60',backgroundColor: '#799F0C'}} > Good Type</Typography.Text>
      
      </Form.Item>
      
        <Form.Item>
      <Select value={goods} onChange={handleGoods}>
  <Option value=''>Good Type</Option>
  <Option value="Homemade">Homemade</Option>
  <Option value="Manufactured_Good">Manufactured_Good</Option>
  <Option value="Services">Services</Option>
  <Option value="Others">Others</Option>
  <Option value="Secondhand_Goods">Secondhand_Goods</Option>
</Select>
</Form.Item>
      
      </div>
      
     

      
      
     </div>
     <div>
     <Divider dashed  style={{backgroundColor:'#25fd60'}}/>
</div>
     


  {myFiltdata && myFiltdata.map(record=>{
    

    return(
      <div >
<Space split={<Divider type="vertical"  style={{backgroundColor:'#020024'}}plain={false}  dashed={true}/>}>

 <Card style={{'border-style': 'dashed', 'border-width': 'thick','border-color':'#01061d'}} title={  <Typography.Title level={1} strong={true} style={{color:'#9e2206'}}><u>Product Details</u></Typography.Title> } cover={<img alt="example"  src={record.img}/>}

 >
  <Typography.Title level={2} strong={true} style={{color:'#61023'}}>Person/Organization Name:{record.Names} <br></br>Product Title/Name:{record.title}<br></br> Product Description:{record.Prod_desc}<br></br>Good Type :{record.Good_type} <br></br> Contact :{record.contact_me}<br></br>Address:{record.address}<br></br>Mode of Transaction:{record.transaction} </Typography.Title>
 </Card>

 

    </Space>
      </div>
    )
  
  })}
      </div>
         ) 
      
   
      
    
    
     
    
  
}

export default Home
