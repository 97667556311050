import React,{useState,useEffect} from 'react'
import axios from 'axios'
import Entry from './Entry'
import {ShareSocial} from 'react-share-social' 
import { FacebookShareButton, TwitterShareButton,InstapaperShareButton,WhatsappShareButton, } from 'react-share';
import {Divider,Typography,Image,Button,Space,Card,Alert,Popconfirm,notification,Row,Col} from 'antd'
const Post = () => {
  <ShareSocial  
  url ="https://googlevend.com/vend/${id}/"
  socialTypes= {['facebook','twitter']}
  onSocialButtonClicked={ data => console.log(data)}    
/>
  

 
  const token =localStorage.getItem('token')
  const [author,setAuthor]=useState('')
  const[authData,setAuthData]=useState('')
  const[pk,setPk]=useState('')
  const[privateData,setPrivate]=useState('')
  const[count,setCount]=useState('')
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = () =>{
    setOpen(true);
 
      
    
    }
    
    
    

    
  
  const handleOk = async(id) => {
    setConfirmLoading(true);
    
    await axios.delete(`https://googlevend.com/vend/${id}/`,{
      headers:{
        Authorization:`Token ${token}`
      },
    })
      .then(function(response){
     
        window.location.reload(false);
       
      }).catch(function(error){
  
      })
     
    

    

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
 
 

  const handleLogout=async()=>{
await axios.post('https://googlevend.com/accounts/logout/')
.then(function(response){
  localStorage.removeItem('token')
  
  setCount(response.data)

}).catch(function(error){
  
})
  }
 // const authFilter=()=>{
   // setPrivate(authData && authData.filter((item)=>{
    // //return(
       // ( item.author===author)
     // )
  
  //  }))
  
  
 // }
 const authFilter=()=>{
   const DataFilter=( authData && authData.filter((item)=>{
     return (
       (item.author===pk)
     )
   }))
   setPrivate(DataFilter)
  
 }
 useEffect(()=>{
   setPrivate( authData && authData.filter(userData=>{
     return(
      (userData.author===pk)

     )
   })
   )
 })
  useEffect(()=>{
    fetchData()
  },[])
  useEffect(()=>{
    Fetchauth()
  },[])
  //}
  
  const fetchData=async()=>{
    await axios.get('https://googlevend.com/accounts/user/',{
        
            headers :{
                Authorization: `Token ${token}`,
            
        }
    }).then(function(response){
     
      
      setPk(response.data.pk)
      setAuthor(response.data.first_name + " " + response.data.last_name)

     // setAuthor(response.data.pk)
      


    }).catch(function(error){
     

    })
   

    }
  const Fetchauth=async()=>{
    await axios.get('https://googlevend.com/vend/')
      
      
    
      
    .then(function(response){
      setAuthData(response.data)
      
      
      
      
         

       })
       
      
    
      
    
  
    
      
     // setAuthor(response.data.author)
     
    .catch(function(error){
     
          })
  
  }
  

  const handleDel=async(id)=>{
    
    await axios.delete(`https://googlevend.com/vend/${id}/`,{
    headers:{
      Authorization:`Token ${token}`
    },
  })
    .then(function(response){
   
      window.location.reload(false);
     
    }).catch(function(error){

    })
   
  }
  return (
    <div>
 
      {count['detail']}
      {token?
        <div> <div>  <Row> 
          <Col span={18}><Typography.Title level={2} strong={true} style={{color:'#CF7E7E','text-align': 'center'}}>Welcome Back<br></br> {author} </Typography.Title></Col>
          <Col span={6} > <Button type='primary' mark onClick={handleLogout} style={{color:'#66f014'}}>Logout</Button></Col>
          </Row>
      
      <Row><Col ><Typography.Title level={2} strong={true} style={{color:'#25fd60'}}>
           You have Posted  {privateData.length}  Post</Typography.Title></Col></Row></div>
           
           
                     
           <Entry/>
  {privateData && privateData.map(record=>{
    return( 
      <div >
      <Space split={<Divider type="vertical" />}>
      
      
       <Card style={{'border-style': 'dashed', 'border-width': 'thick','border-color':'#01061d'}} title={  <Typography.Title level={1} strong={true} style={{color:'#9e2206'}}><u>Product Details</u></Typography.Title> } cover={<img src={record.img}/>}
      
       >
         <Typography.Title level={2} strong={true} style={{color:'#61023'}}> Product Title:{record.title}<br></br> Product Description:{record.Prod_desc}<br></br> Good Type :{record.Good_type} <br></br> Contact :{record.contact_me}<br></br>Address:{record.address}<br></br>Mode of Transaction:{record.transaction}</Typography.Title>
        
 
         <Popconfirm
      title="Delete"
      description={<Typography.Text strong={true} style={{color:'#61023'}}>Delete Product Name {record.title} ?</Typography.Text>}
      open={open}
      onConfirm={()=>{handleOk(record.id)}}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
     <Button  type='primary'  style ={{color:'#ee1616'}}onClick={showPopconfirm}>Delete Post</Button>

    </Popconfirm>
    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
     <Button type='primary'>
     <FacebookShareButton url={`https://googlevend.com/data/vend/${record.id}/`}>
        Share on Facebook
      </FacebookShareButton>
       </Button>
      
       
    
      &nbsp;&nbsp;&nbsp;
      <Button type='primary'>
      <TwitterShareButton url={`https://googlevend.com/data/vend/${record.id}/`}  title={record.title} quote={'Best Digital online platform for marketing'}>
        Share on Twitter
      </TwitterShareButton>
        </Button>
        &nbsp;&nbsp;&nbsp;
      <Button type='primary'>
      <InstapaperShareButton  description={'Best Digital online platform for marketing'} title={record.title} url={`https://googlevend.com/data/vend/${record.id}/`} >
        Share on Insta
      </InstapaperShareButton>
        </Button>
        &nbsp;&nbsp;&nbsp;
      <Button type='primary'>
      <WhatsappShareButton title={record.title} url={`https://googlevend.com/data/vend/${record.id}/`} >
        Share on Whatsapp
      </WhatsappShareButton>
        </Button>
      
       </Card>
       
      
          </Space>
            
      
 
 





</div>


          )
       
        })}
        
</div>:
        <div> <Typography.Title level={2} type='warning'>To post, please log in first.</Typography.Title> </div>}
    </div>
  )
}

export default Post
