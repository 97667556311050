
import './App.css';
import Login from './comonents/Login';
import Register from './comonents/Register';
import Tabs from  './comonents/Tabs'
import Home from './comonents/Home'
import Entry from './comonents/Entry'
import Post from './comonents/Post'
import Reset from './comonents/Reset'
import FeedBack from './comonents/FeedBack';

import{HashRouter,Routes,Route,BrowserRouter,Switch}   from 'react-router-dom'
function App() {
  return (
    <div className="App">


      <HashRouter>
      <Tabs/>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/' element={<Home/>}/>
        <Route path='/reset' element={<Reset/>}/>
        <Route path='/post' element={<Post/>}/>
        <Route path='/feed' element={<FeedBack/>}/>
      
        
       
        
       


          
      
      </Routes>
      </HashRouter>
     
    
     
     
    </div>
  );
}

export default App;
