import React, { useState } from 'react'
import { Button, Space,Divider,Input,Select } from 'antd';
import {Row,Col} from 'antd'
import { useNavigate } from 'react-router-dom';
import {  Typography } from 'antd';


const Tabs = () => {
  const history =useNavigate()
   const handlePost =()=>{
    history('/post')

  }
  const handleFeed=()=>{
    history('/feed')
  }
  const handleAccount=()=>{
    history('/login')
  }
  const handleHome=()=>{
    history('/')
  }
  return (
    

    <div>
        




      <Space wrap>
      <Divider orientation={'right'}  style={{backgroundColor:"blue"}} />
      <Row >
     
     
      
      
      <Col  flex="auto" span={8}>
      <Button type="primary" danger onClick={handleHome}>Home</Button>
     
      </Col>
      <Col  flex="auto" span={5}>
      <Button style ={{color:'#19eb0d'}} danger onClick={handleAccount}>Login</Button>

      </Col>
      
      

      
        <Col  flex="auto" span={5}><Button onClick={handlePost}  style ={{color:'#19eb0d'}} danger>Post</Button></Col>
        <Col  flex="auto" span={5} ><Button onClick={handleFeed}  style ={{color:'#19eb0d'}}danger>feedback/Report</Button></Col>
      </Row>
      </Space>
      <Divider orientation='left' style={{backgroundColor:"blue"}} />
      </div>
      
     
   
    
    
    

    
  )
}

export default Tabs
