import React,{useState,useEffect} from 'react';
import '../index.css';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input,Typography } from 'antd';
import axios from 'axios';
import { Alert, Space } from 'antd';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const[email,setEmail]=useState('')
    const[password,setPassword]=useState('')
    const[msg,setMsg]=useState([])
    const[msg1,setMsg1]=useState([])
    const[redirect,setRedirect]=useState(false)
    const token=localStorage.getItem('token')
    const history=useNavigate()
    const handleRegister=()=>{
   
         
history('/register')


    }
    const  handleReset=()=>{
      history('/reset')
    }

    const onFinish = async(values) => {
      await axios.post('https://googlevend.com/accounts/login/', {
        email: email,
        password: password
      }
        ).then(function (response) {
          
          
          


          localStorage.setItem('token',response.data.key)
          
          setRedirect(true)
          
          
        })
        .catch(function (error) {
          setMsg(error.response.data)
         
          }
          
          
        )
        console.log('Received values of form: ', values);
        
      };
      if(redirect){
        return <Navigate to='/post'/>
      }
  return (
   
    <div>
      {token ?<div> <Navigate to='/post'/> </div>:<div>
      <Typography.Title level={2} type={'secondary'}>  {msg['non_field_errors']}</Typography.Title>
      <Typography.Title level={2} type={'secondary'}>  {msg['email']}</Typography.Title>
     
  
       
      
           

         <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      
    >
      <Form.Item>
      <Typography  strong style={{ fontSize: '200%','text-align': 'left','padding-left': '1%',color:'#CF7E7E'}} type='warning' >Sign in to Google vend</Typography>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={e=>{setEmail(e.target.value)}} value={email}  />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input value={password} onChange={(e)=>{setPassword(e.target.value)}}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        

         
        <Button onClick={handleReset}>Forgot password</Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" onClick={onFinish} className="login-form-button">
          Log in
        </Button>
        Or<Button danger onClick={handleRegister}>register now!</Button> 
      </Form.Item>
    </Form>
  
</div>
      
  }</div>
  )
  
}

export default Login
